.menu-icon {
    position: fixed;
    top: 34px;
    right: 7%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-left: auto;
    cursor: pointer;
    z-index: 32;


}


.menu-icon-line {
    width: 25px;
    height: 1px;
    background-color: #fff;

}


.menu-icon-line-dark {
    width: 25px;
    height: 1px;
    background-color: #000;
}

.menu-icon-line-last {
    width: 15px;
}


@media (max-width: 640px) {

    .menu-icon {
        top: 34px;
        right: 7%;
        gap: 6px;
    }
    
    
    .menu-icon-line {
        width: 30px;
        height: 1px;
    }
    
    .menu-icon-line-dark {
        width: 30px;
        height: 1px;
    }
    
    .menu-icon-line-last {
        width: 17px;
    }

}


@media (max-width: 425px) {
    .menu-icon {
        top: 34px;
        right: 3%;
        gap: 5px;
    }

}

@media (max-width: 375px) {
    .menu-icon {
        top: 34px;
        right: 7%;
        gap: 5px;
    }
    
/*     
    .menu-icon-line {
        width: 21px;
        height: 1px;
    }
    
    .menu-icon-line-dark {
        width: 21px;
        height: 1px;
    }
    
    .menu-icon-line-last {
        width: 14px;
    } */
}


@media (max-width: 325px) {
    .menu-icon {
        top: 32px;
        right: 7%;
        gap: 5px;
    }
}