


.nav-links {
    list-style: none;
    text-decoration: none;
    overflow: hidden;
}

.nav-links  li  a{
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    font-size: 18px;
    cursor: pointer;

}

.nav-links li a:visited {
    color: #fff;
}



.line-1 {
    transition: all .4s;
}

.line-2 {
    transition: all .4s;
} 

.line-3 {
    transition: all .4s;
}

.open .line-3 {
    opacity: 0;
}


.open .line-1 {
    transform: rotate(45deg);
    height: 3px;
    background-color: #fff;
}

.open .line-2 {
    transform: rotate(-45deg) translate(7px, -6px);
    height: 3px;
    background-color: #fff;
} 

.nav-links-mobile {
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0px;
    background-color: #2491BE;
    opacity: 0;
    transition: all .3s;
    visibility: hidden;
}

.nav-links-mobile ul {
    display: flex;
    flex-direction: column;
    gap: 25px;
    list-style: none;
    text-align: center;
    margin-top: 10%;
}

.nav-links-mobile a {
    text-decoration: none;
    font-size: 22px;
    font-weight: 300;
}

.nav-links-mobile.active {
    opacity: 1;
    visibility: visible;

}