@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    font-family: 'Lato', sans-serif;
}


html,body{ scroll-behavior: smooth}

.body-padding {
    padding: 0 7%;
    position: relative;
}

.med-blue {
    color: #0A99FE;
}


/* RESPONSIVNESS */


@media (max-width: 768px) {
    .body-padding {
        padding: 0 4%;
    }
}


@media (max-width: 640px) {
    .body-padding {
        padding: 0 3%;
    }
}

@media (max-width: 425px) {
    .body-padding {
        padding: 0px 0px;
    }
}




