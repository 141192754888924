.footer {
    background-color: #000;
    background-image: url('../assets/MedittereanSvg.png');
    background-size: cover;
    background-position: center bottom;
    padding: 50px 10%;
    overflow: hidden;
}



.footer-nav {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #fff;
}

.footer .logo {
    margin-bottom: 100px;
}

.footer-nav h4{
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.footer-nav ul li {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
    margin-bottom: 20px;
    opacity: .8;
}

.footer-nav ul li a {
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    margin-bottom: inherit;
    opacity: .8;
    color: inherit;
    text-decoration: none;
}


.footer hr {
    margin-top: 50px;
    opacity: 0.77;
}

.footer p {
    color: #fff;
    opacity: .8;
    font-size: 14px;
    font-weight: 300;
    padding-left: 20px;
    margin-top: 20px;
    letter-spacing: 1.5px;
}

