.logo {
    font-size: 26px;
    padding: 30px 0;
    color: #fff;
    font-weight: 300;
    letter-spacing: 8px;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #000;
    cursor: pointer;
}

.logo-dark {
    color: #000;
    margin-bottom: 0;
}

.logo-bold {
    font-weight: 700;
}

/* LOGO RESPONSVINESS */

/* @media (max-width: 640px) {
    .logo {
        font-size: 24px;
        letter-spacing: 7px;
    }
}


@media (max-width: 575px) {
    .logo {
        font-size: 22px;
        letter-spacing: 6px;
    }
}


@media (max-width: 475px) {
    .logo {
        font-size: 20px;
        letter-spacing: 5px;
    }
} */


