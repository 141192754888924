.header {
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
    
}

header img {
    width: 50%;
    margin-bottom: 30px;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mobile {
    display: none;
}

.desktop {
    display: block;
}



video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
  }


.video-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.34);
}

.header h1 {
    margin-bottom: 30px;
    color: #fff;
}

.header p {
    position: absolute;
    top: 57%;
    left: 50%;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 20px;
    letter-spacing: 2px;
    transform: translate(-50%,-50%);
    text-transform: uppercase;
    font-weight: 300;
    opacity: 0.85;
}

.section {
    width: 100%;
    height: 100vh;
    background-size: cover;
    padding: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.section-second {
    background-image: url('../../assets/second-section.jpg');
    color: #fff;
    font-weight: 300;
    letter-spacing: 2px;
    display: flex;
    align-items: flex-start;
    justify-content: top;
    padding: 150px;
}

.section-second p{
    line-height: 180%;

}
.section-second .p-bigger {
    text-transform: uppercase;
    font-size: 27px;
    letter-spacing: 1px;
}

.zidane {
    display: block;
    text-align: right;
    margin-right: -27%;
    font-size: 20px;
    opacity: .8;
}

.zidane-wrap {
    white-space: normal;
}

.section-fashion {
    background-image: url('../../assets/fashion-section.jpg');
}

.section-cuisine {
    background-image: url('../../assets/cuisine-section.jpg');
}

.section-yacht {
    background-image: url('../../assets/yacht-section.jpg');
    background-size: cover;
    background-position: bottom;
}

.section-beauty {
    background-image: url('../../assets/beauty-section.jpg');
    position: relative;
}

.section-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.section-cta-beauty {
    position: absolute;
    bottom: 150px;
    left: 25%;
    transform: translateX(-25%);
}

.event-name {
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-size: 34px;
}

.event-name-blue {
    color: #5DABCC;
}

.btn-primary{
   padding: 16px 30px;
   border-radius: 1px;
   border: none;
   text-transform: uppercase;
   background-color: #fff;
   font-weight: 600;
   cursor: pointer;
}

.section-sponsors {
    padding: 80px;
    display: flex;
    flex-direction: column;
    gap: 120px;
}

.section-sponsors h3 {
    font-size: 26px;
    text-align: center;
    color: #000;
    font-weight: 300;
}

.image-wrapper {
    display: flex;
    justify-content: center;
}

.image-wrapper img {
    width: 80%;
}


/* RESPONSIVNESS */


@media (max-width: 2345px) {   
    .header img {
        width: 66%;
    }

    .header p {
        top: 57%;
        left: 50%;
        font-size: 30px;
    }
}

@media (max-width: 1789px) {   

    /* HEADER RESPONSIVNESS */

    .header p {
        top: 54%;
        left: 50%;
        font-size: 25px;
    }

    .header img {
        width: 66%;
    }


    /* SECTION RESPONSIVNESS */


    .section {
        width: 100%;
        height: 100vh; 
        background-size: cover;
        background-position: center;
       
    }
    
    .section-second {
        letter-spacing: 2px;
        padding: 150px;
    }

    .section-fashion {
        background-position: top;
    }
    
    .section-second p{
        line-height: 180%;
    }
    
    .section-second .p-bigger {
        font-size: 27px;
        letter-spacing: 1px;
        text-align: center;
    }
    
    .zidane {
        margin-right: -27%;
        font-size: 20px;
        opacity: .8;
    }


}

@media (max-width: 1440px) {   
    .header img {
        width: 70%;
    }

    .header p {
        top: 53%;
        left: 50%;
        font-size: 20px;
    }

     /* SECTION RESPONSIVNESS */


    
    .section-second {
        letter-spacing: 1px;
        padding: 150px 0px;
    }
    
   
    .section-second .p-bigger {
        font-size: 25px;
        letter-spacing: .5px;
    }
    
    .zidane {
        margin-right: -20%;
        font-size: 19px;
    }

}

@media (max-width: 1024px) {   
    .header img {
        width: 72%;
    }

    .header p {
        top: 52%;
        left: 50%;
        font-size: 18px;
    }


    
     /* SECTION RESPONSIVNESS */

     .section {
        padding: 77px;
    }
    
     .section-second {
        letter-spacing: .5px;
        padding: 120px 0px;
    }
    
   
    .section-second .p-bigger {
        font-size: 23px;
        letter-spacing: .5px;
    }
    
    .zidane {
        margin-right: -15%;
        font-size: 18px;
    }


    /* CTA RESPONSIVNESS */
    

    .section-cta {
        gap: 25px;
    }
    
    .event-name {
        font-size: 27px;
    }
    
    .btn-primary{
       padding: 14px 28px;
       font-size: 13px;
    }


    /* SPONSORS RESPONSIVNESS */


    .section-sponsors {
        padding: 80px;
        gap: 60px;
    }
    
    .section-sponsors h3 {
        font-size: 20px;
    }
    
    
    .image-wrapper img {
        width: 100%;
    }
    
  
}


@media (max-width: 768px) { 
     /*  BEAUTY SECTION RESPONSIVNESS */

     .section-beauty {
        background-image: url('../../assets/beauty-section.jpg');
        position: relative;
        background-position: right;
    }

    .section-cta-beauty {
        padding: 13px 20px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.6);
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;

    }

    .header img {
        width: 82%;
    }

    .header p {
        top: 52%;
        left: 50%;
        font-size: 18px;
        letter-spacing: 1px;
    }

     /* SECTION RESPONSIVNESS */

     .section {
        padding: 60px;
    }

     .section-second {
        letter-spacing: 0px;
        padding: 100px 0px;
    }
    
   
    .section-second .p-bigger {
        font-size: 21px;
        letter-spacing: .5px;
    }
    
    .zidane {
        margin-right: -10%;
        font-size: 16px;
    }

      /* CTA RESPONSIVNESS */
    

      .section-cta {
        gap: 22px;
    }
    
    .event-name {
        font-size: 25px;
    }
    
    .btn-primary{
       padding: 12px 24px;
       font-size: 12px;
    }

    /* LOGO RESPONSIVNESS */

    .logo {
        font-size: 24px;
        letter-spacing: 7px;
    }

    /* FOOTER RESPONSIVNESS */

    footer {
        background-color: #000;
        padding: 50px 10%;
    }
    
    
    
    .footer-nav {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 50px;
    }
    
    .footer .logo {
        margin-bottom: 80px;
    }
    
    .footer-nav h4{
        font-size: 16px;
    }
    
    .footer-nav ul li {
        font-size: 14px;
        letter-spacing: .5px;
    }
    .footer hr {
        margin-top: 50px;
    }


    /* SPONSORS RESPONSIVNESS */


    .section-sponsors {
        padding: 70px 50px;
        gap: 50px;
    }
    
    .section-sponsors h3 {
        font-size: 18px;
    }
    
    
    .image-wrapper img {
        width: 100%;
    }
    
}


@media (max-width: 640px) {   

     /*  BEAUTY SECTION RESPONSIVNESS */

     .section-beauty {
        background-image: url('../../assets/beauty-section.jpg');
        position: relative;
        background-position: right 2% top 0;
    }

    .section-cta-beauty {
        padding: 13px 20px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.6);
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;

    }

    .header img {
        width: 86%;
    }

    .mobile {
        display: block;
    }

    .desktop {
        display: none;
    }

    .header p {
        top: 52%;
        left: 50%;
        font-size: 16px;
        letter-spacing: 1px;
    }

     /* SECTION RESPONSIVNESS */

     .section {
        padding: 40px;
    }

    .section-second {
        letter-spacing: -.5px;
        padding: 100px 0px;
    }
    
   
    .section-second .p-bigger {
        font-size: 19px;
        letter-spacing: .5px;
    }
    
    .zidane {
        margin-right: 0;
        font-size: 15px;
    }

       /* CTA RESPONSIVNESS */
    

    .section-cta {
        gap: 20px;
    }
    
    .event-name {
        font-size: 24px;
    }
    
    .btn-primary{
       padding: 12px 24px;
       font-size: 12px;
    }

    /* LOGO RESPONSIVNESS */

    .logo {
        font-size: 19px;
        letter-spacing: 4px;
    }

    /* SPONSORS RESPONSIVNESS */


    .section-sponsors {
        padding: 60px 10px;
        gap: 40px;
    }
    
    .section-sponsors h3 {
        font-size: 16px;
    }
    
  
}


/* ONLY FOR ZIDANE :) */


@media (max-width: 500px) {   
    .zidane-wrap {
        white-space: pre;
    }

}





@media (max-width: 425px) {   
   

     /*  BEAUTY SECTION RESPONSIVNESS */

     .section-beauty {
        background-image: url('../../assets/beauty-section.jpg');
        position: relative;
        background-position: right 12% top 0;
     }

    .section-cta-beauty {
        padding: 12px 20px;
        border-radius: 2px;
        bottom: 20px;
    }


    .header img {
        width: 90%;
    }

    .header p {
        top: 52%;
        left: 50%;
        font-size: 15px;
        letter-spacing: -1px;
    }

      /* SECTION RESPONSIVNESS */

   
    .section-second .p-bigger {
        font-size: 20px;
        letter-spacing: .5px;
    }
    
    .zidane {
        margin-right: 3%;
        font-size: 16px;
    }
        /* CTA RESPONSIVNESS */
    

        .section-cta {
            gap: 18px;
        }
        
        .event-name {
            font-size: 22px;
        }
        
        .btn-primary{
           padding: 10px 20px;
           font-size: 11px;
        }

       /* LOGO RESPONSIVNESS */

    .logo {
        font-size: 21px;
        letter-spacing: 2px;
         width: 90%;
         margin: 0 auto;
         padding: 27px 0;
    }

    /* SPONSORS RESPONSIVNESS */

    .section-sponsors {
        padding: 60px 5px;
        gap: 30px;
    }
    
    .section-sponsors h3 {
        font-size: 15px;
    }

    .zidane-wrap {
        white-space: pre;
    }
}

@media (max-width: 375px) {   
    .zidane-wrap {
        white-space: pre;
    }

     /*  BEAUTY SECTION RESPONSIVNESS */

     .section-beauty {
        background-position: right 15% top 0;
     }

    .section-cta-beauty {
        padding: 10px 18px;
        border-radius: 2px;
        bottom: 20px;
    }


    .header img {
        width: 95%;
    }

    .header p {
        top: 51%;
    }

      /* SECTION RESPONSIVNESS */

   
    .section-second .p-bigger {
        font-size: 18px;
        letter-spacing: .5px;
    }
    
    .zidane {
        margin-right: 3%;
        font-size: 14px;
    }

      /* CTA RESPONSIVNESS */
    

      .section-cta {
        gap: 15px;
    }
    
    .event-name {
        font-size: 18px;
    }
    
    .btn-primary{
       padding: 9px 18px;
       font-size: 10px;
    }


          /* LOGO RESPONSIVNESS */

          .logo {
            font-size: 18px;
            letter-spacing: 1px;
             width: 90%;
             margin: 0 auto;
        }


    /* SPONSORS RESPONSIVNESS */

    .section-sponsors {
        padding: 50px 5px;
        gap: 30px;
    }
    
    .section-sponsors h3 {
        font-size: 14px;
    }
}


@media (max-width: 325px) {
    .section-second {
        letter-spacing: -.5px;
        padding: 80px 0px;
    }
    
   
    /* SECTION RESPONSIVNESS */

    
   
    .section-second .p-bigger {
        font-size: 16px;
        letter-spacing: .5px;
    }
    
    .zidane {
        margin-right: 2%;
        font-size: 12px;
    }
    .logo {
        font-size: 17px;
        letter-spacing: 0px;
         width: 90%;
         margin: 0 auto;
    }

}


